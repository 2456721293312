export const ArticlePageBase = '/article/pages'
export const ArticleDetailBase = '/articles'

export const WhitepaperPageBase = '/whitepaper/pages'

export const WhitepaperDetailBase = '/whitepapers'

export const LP = '/lp'

export const TagPageBase = (id: string, pageId: string) => `/tags/${id}/${pageId}`
export const CategoryPageBase = (id: string, pageId: string) => `/categories/${id}/${pageId}`

export const Thanks = "/thanks"

export const PrivacyPolicy = '/IoT_privacy_statement.pdf'
