import '../styles/globals.css'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import type { NextPage } from 'next'
import MediaLayout from '@/layouts/MediaLayout'
import DefaultLayout from '@/layouts/DefaultLayout'
import Head from 'next/head'
import { ArticleTag } from '@/api/types'
import Script from 'next/script';

type NextPageWithoutLayout = NextPage & {
  isWithoutLayout?: boolean
}

type AppPropsWithoutLayout = AppProps & {
  Component: NextPageWithoutLayout
}

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || ''

function MyApp({ Component, pageProps }: AppPropsWithoutLayout) {
  const Layout = MediaLayout
  const tagForSearch = pageProps.tags ? pageProps.tags.map((t: ArticleTag) => ({ label: t.name, value: t.id })) : []

  const title = 'IoTBiz　-ビジネスを加速するIoTメディア-｜IoTBiz｜DXHUB株式会社'
  const description = 'DXHUBが運営するIoTに関するメディア。ビジネスパーソン向けにIoTを軸とした最新動向や活用事例などわかりやすく情報を発信します。'
  const keywords = 'sim,通信'
  const ogpImage = 'https://iot.dxhub.co.jp/ogp.png'
  return (
    <>
      <Head>
        <link rel='preconnect dns-prefetch' href='https://www.googletagmanager.com' crossOrigin='anonymous' />
        <link rel='preconnect dns-prefetch' href='https://images.microcms-assets.io' crossOrigin='anonymous' />
        <link rel="preconnect dns-prefetch" href="https://fonts.googleapis.com/" crossOrigin='anonymous' />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='anonymous' />
        <link rel="preconnect dns-prefetch" href="//www.google-analytics.com" crossOrigin='anonymous' />
        <link rel="stylesheet preload" type="text/css" as="style" href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Sans:wght@400;700&display=swap" crossOrigin="anonymous" />
        <title key="title">{title}</title>
        <meta name="description" content={description} key="description" />
        <meta name="keywords" content={keywords} key="keywords" />
        <meta property="og:type" content="article" key="otype" />
        <meta property="og:title" content={title} key="ot" />
        <meta property="og:description" content={description} key="od" />
        <meta property="og:url" content="https://iot.dxhub.co.jp" key="ou" />
        <meta property="og:image" content={ogpImage} key="oi" />
        <meta property="og:site_name" content="IotBiz" />
        <meta property="og:locale" content="ja_JP" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="IotBiz" />
        <meta name="twitter:image" content={ogpImage} key="ti" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Script
        id="gtm"
        strategy="afterInteractive"
      >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`}</Script>
      {Component.isWithoutLayout ? (
        <DefaultLayout>
          <Component {...pageProps} />
        </DefaultLayout>
      ) : (
        <Layout tags={tagForSearch}>
          <Component {...pageProps} />
        </Layout>
      )}
    </>
  )
}

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}

export function reportWebVitals({ name, value, label, id }: NextWebVitalsMetric): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'web-vitals',
    eventData: {
      category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      action: name,
      value: Math.round(name === 'CLS' ? value * 1000 : value),
      label: id,
      non_interaction: true, // avoids affecting bounce rate.
    },
  });
}

export default MyApp
