import { FC } from 'react'

type Props = {}

const Component: FC<Props> = ({}) => {
  return (
    <>
      <footer className="p-9 bg-primary-900 w-full">
        <div className="flex flex-col gap-4 md:flex-row md:justify-end md:items-baseline md:gap-2">
          <a href="https://dxhub.co.jp/" className="text-white text-xl hover:underline hover:underline-offset-2">
            運営会社
          </a>
          <a
            href="/dxhub_iot_terms.pdf"
            className="text-white text-xl hover:underline hover:underline-offset-2"
          >
            利用規約
          </a>
          <a
            href="https://dxhub.co.jp/privacy-policy"
            className="text-white text-xl hover:underline hover:underline-offset-2"
          >
            プライバシーポリシー
          </a>
        </div>

        <div className="flex flex-col-reverse gap-4 md:flex-row md:justify-between md:items-baseline md:gap-2 mt-6">
          <p className="text-white text-xs md:text-base">Copyright ©2022 DXHUB Inc. All Rights Reserved.</p>
          <p className="text-white text-base">届出電気通信事業者：E-28-03933</p>
        </div>
      </footer>
    </>
  )
}

export default Component
