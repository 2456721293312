import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <>
      <section className="bg-primary-50 min-h-screen flex flex-col">
        <div className="flex-1">{children}</div>
      </section>
    </>
  )
}

export default Layout
