//@ts-nocheck
import { FC } from 'react'
import Link from 'next/link'
import Select, { ActionMeta, SingleValue } from 'react-select'
import { IconSearch } from '@tabler/icons'
import { ArticlePageBase, WhitepaperPageBase } from 'src/route'

export type Props = {
  tags: Options[]
  toTagsPage: (id: string) => void
}

type Option = {
  value: string
  label: string
}

const IndicatorsContainer = (_) => {
  return (
    <>
      <div className="mr-4">
        <IconSearch className="divide-gray-500" width="16" height="16" />
      </div>
    </>
  )
}

const logo = '/logo.png'
const webp = `/logo.webp`
const avif = `/logo.avif`

const Component: FC<Props> = ({ tags, toTagsPage }) => {
  const onChange = (data: SingleValue<Option>, _: ActionMeta<Option>) => {
    toTagsPage(data.value)
  }

  const articlePage = ArticlePageBase + '/1'
  const whitepaperPage = WhitepaperPageBase + '/1'

  return (
    <>
      <header className="flex py-5 md:p-9 items-center justify-between border border-b-divided flex-col lg:flex-row gap-4">
        <div className="flex flex-col mt-4 md:flex-row items-center md:items-baseline gap-4 md:gap-2 justify-center">
          <Link href="/" prefetch={false}>
            <a className="contents">
              <picture className="w-[90px] h-[26px] sm:w-[150px] sm:h-[44px]">
                <source srcSet={avif} type="image/avif" />
                <source srcSet={webp} type="image/webp" />
                <img src={logo} width="100%" height="100%" alt="IoTメディアのロゴ" />
              </picture>
            </a>
          </Link>
          <p className="font-bold md:text-2xl">ビジネスを加速させるIoTメディア。</p>
        </div>

        <nav className="flex gap-3 md:gap-6 items-center">
          <Link href={articlePage} prefetch={false}>
            <a className="font-bold">記事</a>
          </Link>

          <Link href={whitepaperPage} prefetch={false}>
            <a className="font-bold">お役立ち資料</a>
          </Link>

          <Select
            /* @ts-ignore */
            instanceId="selectbox"
            onChange={onChange}
            className="w-[160px] md:w-[200px]"
            closeMenuOnSelect={false}
            options={tags}
            placeholder="検索する"
            components={{ IndicatorsContainer }}
          />
        </nav>
      </header>
    </>
  )
}

export default Component
