import { FC, ReactNode } from 'react'
import Header, { Props as HeaderProps} from '@/components/Header'
import Footer from '@/components/Footer'
import { useRouter } from 'next/router'
import { TagPageBase } from '@/route'

type Props = Pick<HeaderProps, 'tags'> & {
  children: ReactNode
}

const Layout: FC<Props> = ({ tags, children }) => {
  const router = useRouter()
  const toTagsPage = (id: string) => {
    router.push(TagPageBase(id, '1'))
  }
  return (
    <>
      <section className="bg-paper min-h-screen flex flex-col">
        <Header toTagsPage={toTagsPage} tags={tags} />
        <div className="flex-1 my-5">{children}</div>
        <Footer />
      </section>
    </>
  )
}

export default Layout
